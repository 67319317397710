<template>
  <div class="contact">
    <div class="contact-container full-height" ref="contactHeight">
      <div class="adr-container">
        <!--<div class="white-bar"></div>-->
        <div class="text-space" ref="copyHeight">
          <h3>Opening hours</h3>
          <p>
            lun - ven : 9h00 – 12h30, 13h30 - 18h00
            <br />sam : 9h00 – 17h00
          </p>
          <h3>Contact</h3>
          <div itemscope itemtype="http://schema.org/LocalBusiness">
            <div itemscope itemtype="http://schema.org/PostalAddress">
              <span itemprop="name" class="dns">
                Optic Ooms
                <br />
              </span>
              <span itemprop="streetAddress">5, avenue Lou Hemmer</span>
              <br />
              <span itemprop="addressLocality">Mondorf-les-Bains</span>,
              <span itemprop="postalCode">5627</span>
              <br />
              <span itemprop="addressCountry" class="dns">Luxembourg</span>
            </div>
            Open in
            <a
              href="https://www.google.com/maps/search/?api=1&query=Optic%20Ooms,%20Mondorf-les-Bains,%20Luxembourg"
              >Google Maps</a
            >,
            <a
              href="https://maps.apple.com/?address=Avenue%20Lou%20Hemmer%205,%205627%20Mondorf-les-Bains,%20Luxembourg&auid=415440187315694389&ll=49.505034,6.277045&lsp=9902&q=Gianni%20Paoloni%20Optique&_ext=ChoKBQgEEJwBCgQIBRADCgUIBhDzAgoECAoQABImKYdD28cRwEhAMbMoYGicFBlAOQUZASQ4wUhAQbUbZMDGIhlAUAQ%3D"
              >Apple Maps</a
            >
            <br />
            <br />Tel.
            <a href="tel:+35226670401">
              <span itemprop="telephone">+352 26 67 04 01</span>
            </a>
            <br />Email
            <a href="mailto:info@optic.lu">
              <span itemprop="email">info@optic.lu</span>
            </a>
          </div>
        </div>
      </div>
      <div class="map-container" v-bind:style="mapStyles">
        <div class="gmap_canvas">
          <iframe
            width="100%"
            height="100%"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=Optic%20Ooms,%20Mondorf-les-Bains,%20Luxembourg&t=&z=15&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ContactUs",
    data() {
      return {
        mapStyles: { height: "0px" },
      };
    },
    mounted() {
      this.customHeight();
    },
    methods: {
      customHeight() {
        //let heightCopy = this.$refs.copyHeight.clientHeight + 'px'
        //let heightContact = this.$refs.contactHeight.clientHeight + 'px'
        let restHeight =
          this.$refs.contactHeight.clientHeight -
          this.$refs.copyHeight.clientHeight -
          112 +
          "px";
        //Vue.set(this.mapStyles, 'height', restHeight);
        this.mapStyles.height = restHeight;
      },
    },
  };
</script>

<style lang="less">
  .dns {
    visibility: hidden;
    display: none;
  }
  .contact {
    position: absolute;
    width: 100vw;
    //height: 100vh;
    top: 0;

    //margin-top: -112px; // navigation height
    //background-color: #f1f1f1; //#e1e1e1;
    z-index: 1;
  }
  .contact-container {
    padding-top: 112px;
    //display: flex;
    //flex-direction: column;
    //flex-wrap: wrap;
  }
  .adr-container {
    //height: 60%;
    width: 100vw;
    overflow: hidden;
    background: #f5f5f5;
    .white-bar {
      height: 112px;
      background-color: #fff;
      -webkit-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.23);
      -moz-box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.23);
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.23);
    }
    .text-space {
      padding: 20px;
    }
  }
  .map-container {
    //height:40%;
    width: 100vw;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100vw;
  }
</style>
