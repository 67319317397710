<template>
  <div class="doctena">
    <iframe
      style="width: 100%; height: 100%; border: none"
      src="https://booking-app.doctena.com/fr/doctor/466098af-9514-4183-8b39-197fdbd7ab09/1?"
      scrolling="no"
    ></iframe>
  </div>
</template>

<script>
  export default {
    name: "DoctenaBooking",
  };
</script>

<style>
  .doctena {
    height: 100vh;
    text-align: center;
  }
</style>
