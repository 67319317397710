<template>
  <div class="category">
    <PhotoGrid :hashtag-filter="hashTag" />
  </div>
</template>

<script>
  import PhotoGrid from "@/components/PhotoGrid.vue";

  export default {
    name: "ContentCategory",
    props: {
      hashTag: {
        type: String,
        default: () => "",
        required: true,
      },
    } /*,
  data () {
    return {
      hashtagCategoryFilter: this.$route.matched[1].props.hashTag
    }
  }*/,
    components: {
      PhotoGrid,
    },
  };
</script>
