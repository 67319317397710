import Vue from "vue";
import Router from "vue-router";

import Home from "@/views/HomePage.vue";
import ContentCategory from "@/views/ContentCategory.vue";
import Doctena from "@/views/DoctenaBooking.vue";
import Contact from "@/views/ContactUs.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/glasses",
      name: "glasses",
      component: ContentCategory,
      props: { hashTag: "lunettes" },
    },
    {
      path: "/protections",
      name: "sunglasses",
      component: ContentCategory,
      props: { hashTag: "protection" },
    },
    {
      path: "/appointment",
      name: "appointment",
      component: Doctena,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
  ],
});
