<template>
  <div class="home full-height">
    <PhotoGrid />
  </div>
</template>

<script>
  // @ is an alias to /src
  import PhotoGrid from "@/components/PhotoGrid.vue";

  export default {
    name: "HomePage",
    components: {
      PhotoGrid,
    },
  };
</script>

<style lang="less">
  .home {
    width: 100vw;
    //height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0;
    padding-top: 112px;
    //z-index: 1;
    .photo-grid {
    }
  }
</style>
