import Vue from "vue";
import Vuex from "vuex";

import json from "./instagram.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    instagramProfileName: "",
    instagramProfileImage: "",
    instagramFeed: [],
    instagramHomeFeed: [],
    instagramFeedError: null,
  },
  mutations: {
    RECEIVE_INSTAGRAM_FEED(state, response) {
      state.instagramFeed = response.data.media;
      state.instagramProfileName = response.data.profile_name;
      state.instagramProfileImage = response.data.profile_image;
      state.instagramFeedError = null;

      // Creating Random Home Grid
      const max = state.instagramFeed.length;
      const gridImages = 30;
      let prevIndex = 0;
      let rand = 0;

      for (var i = 0; i < gridImages; i++) {
        do {
          rand = Math.floor(Math.random() * max);
        } while (rand == prevIndex);
        state.instagramHomeFeed.push(state.instagramFeed[rand]);
        prevIndex = rand;
      }
    },
    RECEIVE_INSTAGRAM_FEED_ERROR(state, error) {
      state.instagramFeedError = error;
    },
  },
  getters: {
    instagramFeedFiltered: (state) => (filter) => {
      if (filter) {
        return state.instagramFeed.filter((obj) => {
          if (obj.tags.indexOf(filter) === -1) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        return state.instagramFeed;
      }
    },
  },
  actions: {
    downloadInstagramFeed({ commit }) {
      commit("RECEIVE_INSTAGRAM_FEED", { data: json });
    },
  },
});
