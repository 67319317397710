import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

// Lazy Load Images
import VueClazyLoad from "vue-clazy-load";
Vue.use(VueClazyLoad);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
