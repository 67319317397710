<template>
  <div class="photo-grid grid" :class="{ category: hashtagFilter }">
    <clazy-load
      v-for="photo in photos"
      :key="photo.url"
      :src="photo.media_url | cloudinaryL"
      :ratio="0.01"
      class="instagram-item"
    >
      <transition name="fade">
        <img :src="photo.media_url | cloudinaryL" class="instagram-image" />
      </transition>
      <transition name="fade" slot="placeholder">
        <img
          :src="photo.media_url | cloudinaryPreview"
          class="instagram-image-preview"
        />
      </transition>
    </clazy-load>
  </div>
</template>

<script>
  //import { BarLoader } from '@saeris/vue-spinners'

  export default {
    name: "PhotoGrid",
    props: {
      hashtagFilter: {
        type: String,
        default: () => null,
        required: false,
      },
    },
    computed: {
      photos() {
        //return this.$store.state.instagramFeed
        if (this.hashtagFilter === null) {
          return this.$store.state.instagramHomeFeed;
        } else {
          return this.$store.getters.instagramFeedFiltered(this.hashtagFilter);
        }
      },
    },
    filters: {
      cloudinaryM: function (value) {
        if (typeof value === "string" && value != "") {
          return value.replace("upload/", "upload/t_InstagramM/");
        } else {
          return "";
        }
      },
      cloudinaryL: function (value) {
        if (typeof value === "string" && value != "") {
          return value.replace("upload/", "upload/t_InstagramL/");
        } else {
          return "";
        }
      },
      cloudinaryPreview: function (value) {
        if (typeof value === "string" && value != "") {
          return value.replace("upload/", "upload/t_Instagram_Preview/");
        } else {
          return "";
        }
      },
    },
    components: {
      //BarLoader
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  @supports (display: grid) {
    .grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
      grid-auto-rows: 1fr;
      grid-auto-flow: row dense;
    }

    .grid::before {
      content: "";
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    .grid > *:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }

    /* Just to make the grid visible */

    .photo-gridd {
      background-color: black;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(22rem, 1fr));
      grid-gap: 0rem;
    }

    .instagram-item {
      transition: opacity 250ms ease-out;
      opacity: 0.5;
      width: auto;
      min-height: 300px;
      max-height: 400px;
      margin: 0;

      .loading-indicator {
        margin: 0 auto;
        top: 50%;
      }
    }
    .category {
      .instagram-item {
        opacity: 1;
      }
    }
    .instagram-image-preview,
    .instagram-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 250ms ease-out;
    }

    .instagram-image-preview {
      filter: blur(6px);
    }
  }
</style>
