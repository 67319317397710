<template>
  <div id="app">
    <div class="navigation">
      <div class="logo-container">
        <div class="logo">
          <router-link :to="{ name: 'home' }" exact>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.6 89.52">
              <path
                class="cls-1"
                d="M108.71.74c5.52,0,8.12.64,10.56,2.5a12,12,0,0,1,4.19,9.34c0,3.71-1.64,7.48-4,9.33-2.6,2-5.25,2.66-10.34,2.66h-6.32V40h-4.4V.74Zm-5.94,20H109c3.29,0,5.31-.37,7.27-1.64a7.72,7.72,0,0,0,2.92-6.37c0-2.81-1.22-5.3-3-6.42-2-1.27-4-1.64-7.32-1.64h-6.16Z"
              />
              <path
                class="cls-1"
                d="M137.57,4.59h-9.66V.71h23.77V4.59H142V40h-4.4Z"
              />
              <path class="cls-1" d="M160.93.74h4.35V40h-4.35Z" />
              <path
                class="cls-1"
                d="M212.6,29.45c-2.55,6-9.39,11.24-18,11.24a20.12,20.12,0,0,1-20.48-20.32,20.27,20.27,0,0,1,38.52-8.86h-4.72A15.78,15.78,0,0,0,194.5,3.93a16,16,0,0,0-16.07,16.39c0,9.29,6.95,16.5,16.07,16.5a16,16,0,0,0,13.38-7.37Z"
              />
              <path
                class="cls-2"
                d="M98.37,88.78V49.52h6.16l15.66,32.94L135.8,49.52H142V88.78h-4.14l.11-34.91L121.57,88.78h-2.81L102.35,53.87l.11,34.91Z"
              />
              <path
                class="cls-2"
                d="M155.28,77.85c.63,6.52,5.41,7.9,7.85,7.9A7.69,7.69,0,0,0,171,78.27c0-5.51-4.72-6.74-9.28-8.22-3.19-1-10-3-10-10.4-.11-6.95,5.68-10.82,11.67-10.82,4.88,0,10.51,2.7,11.14,10.13h-4.35c-.48-2.55-1.75-6.42-6.95-6.42-3.92,0-7.16,2.65-7.16,6.74-.05,4.77,3.82,5.94,9.76,8,3.71,1.43,9.55,3.5,9.55,10.71,0,6.42-4.83,11.52-12.15,11.52-6.58,0-12-4.14-12.31-11.62Z"
              />
              <path
                class="cls-1"
                d="M69.05,9.46A10.92,10.92,0,1,1,58.14,20.37,10.93,10.93,0,0,1,69.05,9.46m0-9.46A20.38,20.38,0,1,0,89.43,20.37,20.37,20.37,0,0,0,69.05,0Z"
              />
              <path
                class="cls-2"
                d="M69.05,48.77A20.37,20.37,0,0,0,49,65.69a13.91,13.91,0,0,0-8.56,0,20.38,20.38,0,1,0,0,6.91,7.72,7.72,0,0,1,8.56.07,20.37,20.37,0,1,0,20-23.87ZM20.37,80.06A10.92,10.92,0,1,1,31.29,69.15,10.92,10.92,0,0,1,20.37,80.06Zm48.68,0A10.92,10.92,0,1,1,80,69.15,10.92,10.92,0,0,1,69.05,80.06Z"
              />
            </svg>
          </router-link>
        </div>
      </div>
      <div
        v-on:click="switchNav()"
        :class="{ active: navOpen }"
        class="nav_button_container"
      >
        <span class="top"></span>
        <span class="middle"></span>
        <span class="bottom"></span>
      </div>
    </div>

    <router-view />

    <div class="circle_container" :class="{ open: navOpen }">
      <div class="circle_container_2" :class="{ open: navOpen }">
        <div class="circle"></div>
      </div>
    </div>

    <div class="nav_overlay" :class="{ open: navOpen }">
      <nav class="overlay-menu">
        <ul>
          <li>
            <router-link :to="{ name: 'home' }" @click.native="switchNav" exact>
              <span>Home</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'glasses' }"
              @click.native="switchNav"
              exact
            >
              <span>Glasses</span>
            </router-link>
          </li>
          <!--<li><a href="#">Contact Lenses</a></li>-->
          <li>
            <router-link
              :to="{ name: 'sunglasses' }"
              @click.native="switchNav"
              exact
            >
              <span>Sunglasses</span>
            </router-link>
          </li>
          <li>
            <a href="https://letzshop.lu/mondorf/vendors/optic-ooms">
              <span>Shop</span>
            </a>
          </li>
          <li>
            <router-link
              :to="{ name: 'appointment' }"
              @click.native="switchNav"
              exact
            >
              <span>Appointment</span>
            </router-link>
          </li>
          <li>
            <router-link
              :to="{ name: 'contact' }"
              @click.native="switchNav"
              exact
            >
              <span>Contact</span>
            </router-link>
          </li>

          <li class="social">
            <a href="https://www.instagram.com/oomsoptic/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="35px"
                height="35px"
              >
                <path
                  class="cls-2"
                  d="M 16.5 5 C 10.16639 5 5 10.16639 5 16.5 L 5 31.5 C 5 37.832757 10.166209 43 16.5 43 L 31.5 43 C 37.832938 43 43 37.832938 43 31.5 L 43 16.5 C 43 10.166209 37.832757 5 31.5 5 L 16.5 5 z M 16.5 8 L 31.5 8 C 36.211243 8 40 11.787791 40 16.5 L 40 31.5 C 40 36.211062 36.211062 40 31.5 40 L 16.5 40 C 11.787791 40 8 36.211243 8 31.5 L 8 16.5 C 8 11.78761 11.78761 8 16.5 8 z M 34 12 C 32.895 12 32 12.895 32 14 C 32 15.105 32.895 16 34 16 C 35.105 16 36 15.105 36 14 C 36 12.895 35.105 12 34 12 z M 24 14 C 18.495178 14 14 18.495178 14 24 C 14 29.504822 18.495178 34 24 34 C 29.504822 34 34 29.504822 34 24 C 34 18.495178 29.504822 14 24 14 z M 24 17 C 27.883178 17 31 20.116822 31 24 C 31 27.883178 27.883178 31 24 31 C 20.116822 31 17 27.883178 17 24 C 17 20.116822 20.116822 17 24 17 z"
                />
              </svg> </a
            >&nbsp;
            <a href="https://www.facebook.com/opticooms/" target="_blank">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="35px"
                height="35px"
              >
                <path
                  class="cls-2"
                  d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
  export default {
    name: "App",
    data() {
      return {
        navOpen: false,
      };
    },
    mounted() {
      this.$nextTick(function () {
        // Added non-passive event listener to a scroll-blocking event
        let supportsPassive = false;
        try {
          var opts = Object.defineProperty({}, "passive", {
            get: function () {
              supportsPassive = true;
              return 0;
            },
          });
          window.addEventListener("testPassive", null, opts);
          window.removeEventListener("testPassive", null, opts);
        } catch (e) {
          // eslint-disable-next-line
        }
        window.addEventListener(
          "resize",
          this.updateViewHeight,
          supportsPassive ? { passive: true } : false
        );

        //Init
        this.updateViewHeight();
      });
    },
    created() {
      this.$store.dispatch("downloadInstagramFeed");
    },
    methods: {
      switchNav() {
        this.navOpen = !this.navOpen;
      },
      updateViewHeight() {
        // eslint-disable-next-line
        //console.log('Updating CSS Var')
        this.$el.style.setProperty("--vh", window.innerHeight * 0.01 + "px");
      },
    },
  };
</script>

<style lang="less">
  // Colors
  @colorA: rgba(0, 188, 219, 1);
  @colorB: rgba(0, 99, 131, 1);
  @colorC: rgba(83, 85, 92, 1);

  // Nav
  @button-height: 28px;
  @button-width: 35px;
  @nav-padding: 25px;
  @logo-height: 62px;
  @color-background: #f5f5f5;
  @color-main: @colorB;
  @color-active: #fff;
  @color-link: #fff;

  @font-face {
    font-family: "Decour-Regular";
    src: url("assets/font/regular.eot");
    src: url("assets/font/regular.eot?#iefix") format("embedded-opentype"),
      url("assets/font/regular.woff2") format("woff2"),
      url("assets/font/regular.woff") format("woff"),
      url("assets/font/regular.ttf") format("truetype");
    font-display: swap;
  }

  body {
    color: @colorB;
  }
  a {
    color: @colorA;
  }

  .full-height {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
  }

  // Navigation

  .navigation {
    background-color: white;
    /*z-index: 19;*/
    position: relative;
    .logo-container {
      padding: @nav-padding;
      position: relative;
      .logo {
        a {
          display: block;
          margin-right: 10%;
        }
        svg {
          height: @logo-height;
        }
      }
    }
  }

  svg {
    .cls-1 {
      fill: #16becf;
    }
    .cls-2 {
      fill: #006383;
    }
    /*.cls-1, .cls-2 {
      fill:#FFFFFF;
    }*/
  }

  .nav_button_container {
    position: absolute;
    right: @nav-padding;
    top: calc(@nav-padding + @logo-height / 2 - @button-height / 2);
    height: @button-height;
    width: @button-width;
    cursor: pointer;
    transition: opacity 0.25s ease;
    z-index: 9999;
    &:hover {
      opacity: 0.7;
    }

    &.active {
      .top {
        transform: translateY(11px) translateX(0) rotate(45deg);
        background-color: @colorA;
      }
      .middle {
        opacity: 0;
        background-color: @colorA;
      }

      .bottom {
        transform: translateY(-11px) translateX(0) rotate(-45deg);
        background-color: @colorA;
      }
    }

    span {
      background-color: @colorB;
      border: none;
      height: 5px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.35s ease;
      cursor: pointer;

      &:nth-of-type(2) {
        top: 11px;
      }

      &:nth-of-type(3) {
        top: 22px;
      }
    }
  }
  // -----------------------------------------------------------------------------
  .circle_container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    z-index: 30;
  }

  .circle_container_2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: ~"calc(50vh - 50vw)" ~"calc(50vw - 50vh)";
    transform: translate(50%, -50%) scale(0.1, 0.1);
    opacity: 0;
    transition: opacity 0.35s, transform 0.45s;

    &.open {
      opacity: 1;
      transform: translate(0, 0) scale(3, 3);
    }

    .circle {
      background: #fff;
      border-radius: 50%;
      height: 100%;
    }
  }

  .nav_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    overflow: hidden;
    z-index: 999;

    &.open {
      opacity: 1;
      visibility: visible;
      height: 100%;
      /*background-color: #fff;*/

      li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.35s;

        &:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.45s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.5s;
        }
        &:nth-of-type(5) {
          animation-delay: 0.55s;
        }
        &:nth-of-type(6) {
          animation-delay: 0.6s;
        }
        &:nth-of-type(7) {
          animation-delay: 0.65s;
        }
      }
    }

    nav {
      position: relative;
      //height: 70%;
      top: 10%;
      //transform: translateY(-50%);
      font-size: 28px;
      /*font-family: 'Vollkorn', serif;
    font-weight: 400;*/
      text-align: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;

      li {
        display: block;
        //height: 10%;
        //height: calc(100% / 10);
        min-height: 80px;
        position: relative;
        opacity: 0;
        padding-right: 0px;

        &.social {
          a {
            display: inline-block;
          }
        }
        a {
          display: block;
          position: relative;
          //color: @color-main;
          color: @colorB;
          text-decoration: none;
          text-transform: uppercase;
          overflow: hidden;

          &:hover:after,
          &:focus:after,
          &:active:after {
            width: 100%;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0%;
            transform: translateX(-50%);
            height: 3px;
            background: @color-main;
            transition: 0.35s;
          }
        }
      }
    }
  }

  @keyframes fadeInRight {
    0% {
      opacity: 0;
      left: 20%;
    }
    100% {
      opacity: 1;
      left: 0;
    }
  }

  /*!
 * Bootstrap Reboot v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  @-ms-viewport {
    width: device-width;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, Noto Sans, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    background-color: #000; //
    font-family: "Decour-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, Noto Sans, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }

  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  strong {
    font-weight: bolder;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  a {
    //color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):hover,
  a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }

  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
      "Courier New", monospace;
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }

  figure {
    margin: 0 0 1rem;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    border-radius: 0;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }
</style>
